/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.login_box {
  width: 500px;
  min-height: 424px;
  padding: 80px 50px 50px 50px;
  background: white;
  box-shadow: 0 5px 20px 0 rgba(0, 83, 155, 0.2);
  border-radius: 20px;
  top: calc(50% - 300px);
  left: calc(50% - 212px);
  position: absolute;
}
.login_box .login_text {
  font-size: 24px;
  color: #333333;
  text-align: center;
  margin-bottom: 30px;
}
.circle_box {
  position: absolute;
  top: -57px;
  left: calc(50% - 57px);
  width: 114px;
  height: 114px;
  border-radius: 50%;
  background: white;
}
.circle_box .img_logo {
  width: 100px;
  position: relative;
  top: 30px;
  left: 5px;
}
.input_phone,
.input_password {
  height: auto;
  font-size: 16px;
  color: #999999;
  text-align: left;
}
.input_phone .input_prefix,
.input_password .input_prefix {
  font-size: 20px;
}
.ant-input-affix-wrapper .ant-input {
  padding-left: 50px;
}
.form_btn {
  width: 100%;
  height: 50px;
  border-radius: 20px;
  font-size: 22px;
  color: #ffffff;
}
.form_btn_sign {
  color: #444;
  margin-top: 20px;
}
.main {
  width: 500px;
  height: 424px;
  padding: 80px 50px 50px 50px;
  position: absolute;
  box-sizing: border-box;
  background: white;
  top: calc(50% - 300px);
  left: calc(50% - 212px);
  box-shadow: 0 5px 20px 0 rgba(0, 83, 155, 0.2);
  border-radius: 20px;
}
@media screen and (max-width: 576px) {
  .main {
    width: 95%;
  }
}
.main .icon {
  margin-left: 16px;
  color: rgba(0, 0, 0, 0.2);
  font-size: 24px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
}
.main .icon:hover {
  color: #1890ff;
}
.main .other {
  margin-top: 24px;
  line-height: 22px;
  text-align: left;
}
.main .other .register {
  float: right;
}
.main .login /deep/ .ant-tabs .ant-tabs-bar {
  margin-bottom: 24px;
  text-align: center;
  border-bottom: 0;
}
.main .login /deep/ .ant-form-item {
  margin: 0 2px 24px;
}
.main .login .getCaptcha {
  display: block;
  width: 100%;
}
.main .login .icon {
  margin-left: 16px;
  color: rgba(0, 0, 0, 0.2);
  font-size: 24px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
}
.main .login .icon:hover {
  color: #1890ff;
}
.main .login .other {
  margin-top: 24px;
  line-height: 22px;
  text-align: left;
}
.main .login .other .register {
  float: right;
}
.main .login .prefixIcon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
}
.main .login .submit {
  width: 100%;
  margin-top: 24px;
}
